<template>
    <section class="content">
        <div class="card">
            <div class="card-header">
                Filter Pencarian
            </div>
                <!-- <div class="p-5"></div> -->
            <div class="card-body row">
                <div class="col-md-6 form-group">
                    <label class="control-label">Tanggal</label>
                    <datepicker
                      placeholder="Tanggal"
                      class="datep"
                      v-model="form.f_tanggal"
                      :options="{format:'yyyy-mm-dd', autoclose: true}" 
                      :autoclose=true
                      required
                    />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Kelas</label>
                    <v-select :options="optKelas" v-model="form.kelas_id" :reduce="opt => opt.id" />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                    <div class="box-tools card-tools toolbar float-left">
                    <router-link to="/penilaian-jasmani/form" class="btn btn-primary btn-flat"
                        ><i class="fa fa-plus"></i> Tambah Penilaian Jasmani
                    </router-link>
                    </div>
            </div>
            <div class="card-body">
                <table class="table table-hover" ref="tableuser">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NAMA SISWA</th>
                            <th>PUSH UP</th>
                            <th>SIT UP</th>
                            <th>PULL UP</th>
                            <th>SHUTTLE RUN (M)</th>
                            <th>LARI (M)</th>
                            <th>RENANG (M)</th>
                        </tr>
                    </thead>
                    <tbody @click="handleClick"></tbody>
                </table>
            </div>
        </div>
    </section>
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
    name: 'PenilaianJasmani',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            optKelas: [],
            roles: '',
            form: {
                f_tanggal: '',
                kelas_id: '',
            },
            formItem: {
                id: '',
                nama_siswa: '',
                count_pushup: '',
                count_situp: '',
                count_pullup: '',
                count_shuttlerun: '',
                count_run: '',
                count_swim: '',
            },
            tgl: '',
            kelas: '',
        }
    },
    created: function() {
        this.form.f_tanggal = moment().format("YYYY-MM-DD");
        // this.roles = this.$route.meta.roles;
        // console.log('load initial data', this.$route)

        authFetch('/akademik/jadwal_tentor/kelas')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optKelas = js.data;
            });
    },
    methods: {
        search: function() {
            let self = this;
            self.tgl = self.form.f_tanggal;
            self.kelas = self.form.kelas_id;
            this.table.api().ajax.reload();
        },
        handleClick(e) {},
    },
    mounted() {
        console.log('load initial data', this.$route.params.roles);
        const e = this.$refs;
        var self = this;

        this.table = createTable(e.tableuser, {
            title: "",
            roles: ["read"],
			frame: true,
            ajax: "/administrasi/penilaian_jasmani",
			language: {
				zeroRecords: "Tidak ada siswa yang absensi!"
			},
            selectedRowClass: "",
            scrollX: true,
            columns: [
                { data: "id",
                sortable: false,
                visible: false, },
                { data: "nama_siswa" },
                { data: "count_pushup" },
                { data: "count_situp" },
                { data: "count_pullup" },
                { data: "count_shuttlerun" },
                { data: "count_run" },
                { data: "count_swim" },
            ],
            filterBy: [1, 2],
            rowCallback: function(row, data) {
                // $("td:eq(1)", row).html(data.count_pushup+' Kali');
                // console.log(data)
            },
            paramData: function ( d ) {
                d.tgl = self.tgl;
                d.kelas_id = self.kelas;
            },
        });
    }
}
</script>